/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url(./fonts/Lato-Regular.woff2) format("woff2"),
    url(./fonts/Lato-Regular.woff) format("woff"),
    url(./fonts/Lato-Regular.ttf) format("truetype");
}

/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url(./fonts/Lato-Bold.woff2) format("woff2"),
    url(./fonts/Lato-Bold.woff) format("woff"),
    url(./fonts/Lato-Bold.ttf) format("truetype");
}

/* lato-900 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"),
    url(./fonts/Lato-Black.woff2) format("woff2"),
    url(./fonts/Lato-Black.woff) format("woff"),
    url(./fonts/Lato-Black.ttf) format("truetype");
}
