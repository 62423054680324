/* More at https://codepen.io/engineerapart/pen/YzNrobG */

.use-loader {
  position: relative;
  width: .5em;
  height: .5em;
  background-color: currentcolor;
  border-radius: 100%;
  animation: atomLoaderFrames1 7.5s infinite linear;
}

.use-loader:before,
.use-loader:after {
  content: '';
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.use-loader:before {
  width: 1em;
  height: 4em;
  animation: atomLoaderFrames2 .8s linear infinite;
}

.use-loader:after {
  width: 4em;
  height: 1em;
  animation: atomLoaderFrames2 1.2s linear infinite;
}

@keyframes atomLoaderFrames1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes atomLoaderFrames2 {
  0% {
    box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
  }

  25% {
    box-shadow: 0.04em 0.04em 0 0.02em currentcolor;
  }

  50% {
    box-shadow: -0.04em 0.04em 0 0.02em currentcolor;
  }

  75% {
    box-shadow: -0.04em -0.04em 0 0.02em currentcolor;
  }

  100% {
    box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
  }
}
